import dayjs from '@/lib/dayjs'
import { GetBusinessDataChartData } from '@/api/operateDashboard'
import { GetOrgChart, GetOrgShopChart } from '@/api/org/dataChart'

//  獲取圖表資料

export const getOrgShopChartData = async ({
  orgId,
  shopId,
  key,
  createdAtStart,
  createdAtEnd,
  dateTrunc,
  type,
  isRepurchase,
  tags,
}) => {
  const [res, err] = await GetOrgShopChart({
    orgId,
    shopId,
    key,
    parameters: {
      createdAtStart,
      createdAtEnd,
      dateTrunc,
      type,
      isRepurchase,
      tags,
    },
  })

  if (err) return null
  return res
}

export const getOrgChartData = async ({
  orgId,
  key,
  createdAtStart,
  createdAtEnd,
  dateTrunc,
  type,
  isRepurchase,
  tags,
}) => {
  const [res, err] = await GetOrgChart({
    orgId,
    key,
    parameters: {
      createdAtStart,
      createdAtEnd,
      dateTrunc,
      type,
      isRepurchase,
      tags,
    },
  })

  if (err) return null
  return res
}

export const getChartData = async ({
  shopId,
  key,
  createdAtStart,
  createdAtEnd,
  dateTrunc,
  type,
  isRepurchase,
  tags,
}) => {
  const [res, err] = await GetBusinessDataChartData({
    shopId,
    key,
    parameters: {
      createdAtStart,
      createdAtEnd,
      dateTrunc,
      type,
      isRepurchase,
      tags,
    },
  })

  if (err) return null
  return res
}

//  根據傳入參數返回日期陣列
export const getDateArray = (num, dateFormat = 'MM/DD') => {
  const result = []
  let count = 0
  while (count < num) {
    result.push(dayjs().subtract(count, 'day').format(dateFormat))
    count++
  }
  return result.reverse()
}

export const getDateOfRange = ({ interval = 1, splitType = 'day' }) => {
  const createdAtEnd = dayjs().format('YYYY/MM/DD 00:00:00')

  let createdAtStart

  switch (interval) {
  case 'month':
    createdAtStart = dayjs().startOf('month')
    break
  case 'year':
    createdAtStart = dayjs().startOf('year')
    break
  default:
    createdAtStart = dayjs().subtract(interval, 'day')
  }

  console.group('🔥🔥🔥🔥🔥')
  console.log(createdAtStart)
  console.groupEnd()

  createdAtStart = dayjs(createdAtStart)
    .startOf(splitType)
    .format('YYYY/MM/DD 00:00:00')

  let currentDate = dayjs(createdAtStart).startOf(splitType)

  const dateArray = []

  while (currentDate.isBefore(dayjs(createdAtEnd))) {
    dateArray.push(dayjs(currentDate).format('YYYY/MM/DD'))
    currentDate = dayjs(currentDate).add(1, splitType)
  }

  return {
    createdAtEnd,
    createdAtStart,
    dateArray,
  }
}

//  取自小數點後兩位
export const truncateDecimal = (number) => {
  return Math.trunc(number * 100) / 100
}

export const dataSourceLabels = ['預約訂單', '電商訂單', '銷售紀錄', '會員商城', '第三方系統']

export const filterFeatureKeysBySource = (checkAction) => {
  const featureKeys = {
    預約訂單: 'admin.appointmentService.page',
    電商訂單: 'admin.ecommercePage.page',
    銷售紀錄: 'admin.salesRecord.page',
    會員商城: 'admin.memberStoreOrder.page',
    第三方系統: 'admin.memberExternalTransaction.page',
  }
  return dataSourceLabels.filter((item) => {
    if (featureKeys[item]) {
      return checkAction(featureKeys[item])
    }
    return false
  })
}
