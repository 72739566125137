<template>
  <div class="chart-title" :class="{ 'chart-title-border': showBorder }">
    <div v-if="title">
      <div v-if="!hint" class="title">{{ title }}</div>
      <FormItemTooltipLabel
        v-else
        :label="title"
        :tooltipWidth="200"
        class="title"
      >
        {{ hint }}
      </FormItemTooltipLabel>
    </div>
    <div v-else />
    <div class="flex flex-col items-end">
      <BaseSearch
        v-if="shopSelector"
        :value.sync="shops"
        :api="apiFormatAdaptor(GetShops)"
        collapse-tags
        multiple
        placeholder="請選擇分店"
        @change="shopsChange"
      />
    </div>
    <div v-if="showOptions" class="flex flex-col items-end">
      <BaseElSelect v-model="currentOption" @change="selectDate">
        <BaseElSelectOption
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
      <div v-if="useRangeType && currentOption !== '30'" class="switch-group">
        <div
          class="switch-btn"
          :class="{ 'active-btn': rangeType === 'week' }"
          @click="selectRange('week')"
        >
          週檢視
        </div>
        <div
          class="switch-btn"
          :class="{ 'active-btn': rangeType === 'month' }"
          @click="selectRange('month')"
        >
          月檢視
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useDateSelect } from '@/use/dashboard'
import { GetShops } from '@/api/shop'
import { apiFormatAdaptor } from '@/utils/api'
import BaseSearch from '@/components/Search/BaseSearch.vue'

export default defineComponent({
  name: 'ChartTitle',
  components: { FormItemTooltipLabel, BaseSearch },
  props: {
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    showOptions: { type: Boolean, default: true },
    showBorder: { type: Boolean, default: true },
    useRangeType: { type: Boolean, default: false },
    getChart: { type: Function, default: () => {} },
    shopSelector: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const {
      selectOptions,
      currentOption,
      rangeType,
      dateSelectHandler,
      rangeTypeHandler,
    } = useDateSelect(props.useRangeType)

    const shops = ref([])

    const selectDate = (val) => {
      dateSelectHandler(val)
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    }

    const selectRange = (val) => {
      rangeTypeHandler(val)
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    }

    const shopsChange = (shop) => {
      emit('shopChange', shop)
    }

    onMounted(() => {
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    })

    return {
      selectOptions,
      currentOption,
      rangeType,
      selectDate,
      selectRange,
      GetShops,
      apiFormatAdaptor,
      shops,
      shopsChange,
    }
  },
})
</script>

<style lang="postcss" scoped>
.chart-title {
  @apply flex items-center justify-between font-medium;
  @apply pb-5;
}
.chart-title-border {
  @apply border-b border-[#ececec];
}
.title {
  @apply text-[#636363];
}
::v-deep .el-input {
  @apply w-[150px];
}

.switch-group {
  @apply flex;
  @apply mt-5;
  @apply border border-[#b3b3b3];
  @apply rounded-md;
  @apply overflow-hidden;
}

.switch-btn {
  @apply text-sm;
  @apply px-5 py-1;
  @apply cursor-pointer;
}

.active-btn {
  @apply text-[#fff];
  @apply bg-[#204bb4];
}
</style>
