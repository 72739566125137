import { render, staticRenderFns } from "./MemberGanderOverview.vue?vue&type=template&id=1b68b56a&scoped=true"
import script from "./MemberGanderOverview.vue?vue&type=script&lang=js"
export * from "./MemberGanderOverview.vue?vue&type=script&lang=js"
import style0 from "./MemberGanderOverview.vue?vue&type=style&index=0&id=1b68b56a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b68b56a",
  null
  
)

export default component.exports