import { orgAdmin } from '../instance'

export const GetOrgChart = async ({
  orgId,
  key,
  parameters,
}) => {
  return await orgAdmin({
    url: `/${orgId}/businessDataChart/org/${key}`,
    method: 'POST',
    data: {
      parameters,
    },
  })
}

export const GetOrgShopChart = async ({
  shopId,
  orgId,
  key,
  parameters,
}) => {
  return await orgAdmin({
    url: `/${orgId}/businessDataChart/orgShop/${key}`,
    method: 'POST',
    data: {
      shopId,
      parameters,
    },
  })
}
