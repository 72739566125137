<template>
  <div class="total-purchase-statistics">
    <ChartTitle title="總消費單數" :getChart="getChart" />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateGanderConfig, getPurchaseData } from '../../common'
import { usePermissions } from '@/use/permissions'
import { filterFeatureKeysBySource } from '@/utils/dashboard'
import { useOrg } from '@/use/useOrg'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'TotalPurchaseStatistics',
  components: { ChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateGanderConfig())
    const { shopId } = useShop()
    const { orgId } = useOrg()
    const { checkAction } = usePermissions()

    const getChart = async ({ interval }) => {
      const { newOptions } = await getPurchaseData({
        shopId: shopId.value,
        orgId: orgId.value,
        interval,
        target: 'count',
        typeKeys: filterFeatureKeysBySource(checkAction),
      })

      updateChart(newOptions)
    }

    return {
      chartRef,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.total-purchase-statistics {
  @apply flex flex-col gap-5;
}
</style>
